/*
    Nos Usines à Talents
    All Rights Reserved
    Author:
        Merchrist KIKI
        alexismerchrist.kiki@gmail.com
    2021
*/


/* Common styles */
@import url(https://fonts.googleapis.com/css?family=Raleway:400,700);

body {
    font-family: 'Raleway', sans-serif;
    margin: 0;
    padding: 0;
    line-height: 2em;
    background-color: #f6f6f6;
}

.app-container {
    padding: 0px;
}

.main-wrapper {
    margin-top: 80px;
    min-height: 50vh;
    flex-direction: column;
}

/* .app-container .splash {
    background-image: url("splash.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
} */

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-active {
    color: #dc3232;
}

.text-muted {
    color: #666;
}

.vbar {
    border-right: 1px solid black;
    height: 20px;
}

.wrapper1 {
    color: #666;
    padding-bottom: 1.5em;
    padding-top: 1em;
}

.wrapper2 {
    background-color: #f6f6f6;
    padding-bottom: 1.5em;
    padding-top: 1em;
}

.wrapper.white {
    background-color: #fff;
    padding-bottom: 1.5em;
    padding-top: 1em;

}

.wrapper {
    background-attachment: fixed;
    background-position-x: center;
    /* background-size: cover; */
    background-size: cover;
    background-color: white;
    /* padding-top: 120px; */
    padding-bottom: 90px;

}

.wrapper.light {
    background-color: #f6f6f6;
}

.wrapper.splash {
    background-image: url("./splash.jpg");
    padding-top: 160px;
}

.wrapper.splash2 {
    padding-top: 100px;
    background-attachment: local;
    background-image: url("./splash2.png");
}

.wrapper.splash2 .flex {
    position: absolute;
    bottom: -10vh;
    width: 100%;
}

.wrapper.splash2 .sector-card-rotated {
    z-index: 5;
}

.nuat-title {
    margin-top: 50px;
    margin-bottom: 20px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: transparent;
}

.nuat-title.light {
    color: #aaa;
}

.nuat-title strong {
    color: #dc3232;
    font-weight: 500;
}

/* Overlay and popup styles */
.overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.overlay .popup {
    position: absolute;
    top: 10%;
    /* left: 15%; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin: auto;
    width: 70%;
    height: 90%;
    animation: move-in-left .3s ease-in-out forwards;
}

@keyframes move-in-left {
    0% {
        left: -13%
    }

    100% {
        left: 15%
    }
}



/* 1- Navbar style */
.navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    width: 98%;
    /* width: fit-content; */
    height: 70px;
    padding-left: 32px;
    padding-right: 32px;
    /* background-color: transparent; */
    background-color: white;
    border-bottom: 2px solid #E5E5E5;
    z-index: 9;
}

.navbar.splash::before {
    position: fixed;
    transform: translateY(0px);
    height: 0;
}

.navbar::before {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(#E5E5E5, #f6f6f6);
    transform: translateY(70px);
}

.navbar-logo {
    /* width: 220px; */
    /* height: 51px; */
    margin-top: 15px;
}

.navbar-content {
    /* position: relative;
    right: 10px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #666;
    font-weight: 500;
    margin-right: 20px;
    font-size: 18px;
}

.navbar-content .active {
    color: #dc3232;
}

.navbar-content a, .navbar-content .vbar {
    margin-left: 20px;
}

.navbar-content a {
    color: #666;
    text-decoration: none;
    cursor: pointer;
}


.banner {
    padding: 2em 0 2em 0;
}

.banner_content {
    text-align: center;
    padding: 0 0 4em 0;
}

.banner_content h2 {
    font-size: 3.5em;
    color: white;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1.3em;
}
@media only screen and (max-width: 600px) {
    .banner_content h2 {
        font-size: 2em;
    }
}

.banner_content p {
    text-transform: uppercase;
    color: #aaa;
    font-size: 1.5em;
    font-weight: 400;
}
@media only screen and (max-width: 600px) {
    .banner_content p {
        font-size: 1.2em;
    }
}

.row_header_button {
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
}

.row_header_button h2 {
    text-transform: uppercase;
    font-size: 3em;
    line-height: 1.3em;
    color: #484848;
    overflow: wrap-content;
    margin-bottom: 15px;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
}

@media only screen and (max-width: 600px) {
    .row_header_button {
        margin-left: 0;
        margin-right: 0;
    }
    .row_header_button h2 {
        font-size: 1.75em;
    }
}

.container_horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .container_horizontal {
        flex-direction: column;
    }
}


.bicolumn {
    width: 35%;
}

@media only screen and (max-width: 600px) {
    .bicolumn {
        width: 90%;
        margin: 0%;
    }
}

.linkstyle:active, .linkstyle:focus {
    outline: none;
    border: 0px;
}

.button {
    background-color: #dc3232;
    color: #fff;
    font-size: 1.35em;
    text-transform: uppercase;
    padding: 0.7em;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    border-radius: 4px;
	text-align: center;
	margin: 10px;
	border-width: 0px;
}

.button:hover, .button:active {
    background-color: #e25252;
}

/* About page */

.container-about {
    /* background-color: #323037; */
    padding-top: 150px;
    height: 700px;
}

.about-content {
    text-transform: none;
    color: #aaa;
    font-size: 1.4em;
    font-weight: 400;
}

.goal-cards {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 250px;
    width: 100%;
}

.goal-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    width: 33%;
    height: 90%;
    background-color: rgba(0,0,0,0.2);
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 15px;
}

.goal-card p {
    text-transform: none;
    color: rgb(223, 223, 223);
    font-size: 1.2em;
    font-weight: 500;
    text-justify: inter-word;
    text-align: justify;
}

.logos {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 50px 100px 50px 100px;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 130px;
    margin-left: 50px;
}

@media only screen and (max-width: 800px) {
    .logos {
        display: block;
        padding: 1px;
    }

    .logo {
        margin-left: 5px;
    }
}

/* Loader */

.loaderArea {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    left: 0;
    top: 0;
    width: 100%;
    height: 55vh;
    /* z-index: 99; */
}
  
.fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    animation: spin 1.75s linear infinite;
}

.loadWatcher {
    /* height: 500px; */
    background-color: #e25252;
    /* background-color:#f6f6f6; */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



/* 2- Sectors view style */
.bars-fixed {
    position: fixed;
    top: 80px;
    background-color: #f6f6f6;
    height: 70px;
    width: 100%;
    z-index: 9;

}

.bars-fixed.splash {
    top: 90px;
    background: transparent;
    padding-left: 30px;
}

.bars-fixed>.flex {
    position: fixed;
    top: 100px;
}

.bars-left {
    left: 60px;
    left: 4%;
}

.bars-right {
    right: 45px;
    right: 3%;
}

.bar-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: fit-content;
    height: 20px;
    margin-right: 15px;
    padding: 8px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
}

.bar-wrapper * {
    /* height: 80%; */
    margin-right: 5px;
}

.bar-wrapper .last {
    margin-right: 0px;
}

.bar-wrapper input {
    font-size: 14px;
    border: none;
    width: 250px;
    margin-left: 5px;
}

.bar-wrapper .flex {
    height: 20px;
    font-size: 1.2em;
}

.bar-wrapper i {
    margin-left: 5px;
}

.bar-wrapper input:focus {
    outline: none;
    /* border-bottom: 1px solid #E5E5E5; */
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
    margin-left: 5px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #dc3232;
}

input:focus+.slider {
    box-shadow: 0 0 1px #dc3232;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.card-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* CHANGE THIS LATER */
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    /* padding-left: 3.5%;
    padding-right: 3.5%; */
    /* width: 100%; */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sector-card {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: clamp(200px, 340px, 420px);
    margin: 0 10px 20px 10px;
    height: 410px;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px -1px 2px rgba(0, 0, 0, 0.07);
}


.sector-image {
    position: relative;
    max-height: 88%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("sector-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    animation-timing-function: cubic-bezier(0.5, 1.62, 0.65, 1);
}

.sector-image img {
    position: relative;
    top: 0;
    left: 0;
    /* width: 100%; */
    height: 100%;
    /* max-width: 100%; */
}

.sector-card .card-body {
    width: 100%;
    height: 12%;
}

.card-info {
    display: flex;
    /* width: 100%; */
    align-items: center;
    justify-content: space-between;
    text-align: left;
}

.card-info h2 {
    position: relative;
    left: 10px;
    /* max-width: 220px; */
    /* max-height: 32px; */
    margin: 6px 0 8px 0;
    font-size: 28px;
    font-weight: 400;
    overflow: hidden;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
}


.left {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 10px;
    font-size: 20px;
}

.left span {
    margin-right: 1px;
}

.heart-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-top: 5px;
}

.heart-icon i {
    display: inline-block;
}

.heart-icon i[class="fa fa-heart"],
.heart-icon i[class="far fa-heart"]:hover {
    color: #ff3144;
}

.card-description {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    line-height: 21px;
}

.more-info-em {
    cursor: pointer;
}

.more-info-em:hover {
    color: #dc3232;
}

.sector-card-rotated {
    transform: translate(0px, 30px) rotate(-10deg);
    z-index: 30;
}

.sector-card-rotated * {
    margin: 0;
}

.video-card {
    position: relative;
    padding: 10px;
    width: 550px;
    height: 490px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    z-index: 0;
}

.video-card>.close-btn {
    position: absolute;
    top: -50px;
    right: -40px;
    width: 35px;
    height: 35px;
    border: none;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.3em;
}

.close-btn:hover {
    color: #dc3232;
    opacity: 0.9;
}

/* .video-card .video {
    width: 100%;
    height: 70%;
    background-color: rgba(0, 0, 0, 0.5);
} */

.video { 
    width: 100%;
    height: 70%;
    overflow: hidden; 
    padding-bottom: 56.25%; 
    position: relative; 
    height: 0;
}

.video iframe {
    left: 0; 
    top: 0; 
    height: 100%;
    width: 100%;
    position: absolute;
}

.video-card .card-description {
    /* white-space: pre;  */
    margin-top: 10px;
    height: 28%;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 22px;
}

.video-card>.learn-more-btn {
    position: absolute;
    bottom: -60px;
    right: 0px;
    display: flex;
    padding: 6px 10px;
    border: none;
    background-color: #dc3232;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.5em;
}


.learn-more-btn:hover {
    opacity: 0.8;
}

.product-card {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: min(98%, 500px);
    margin: 0 15px 30px 15px;
    height: 150px;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px -1px 2px rgba(0, 0, 0, 0.07);
    cursor: poiner;
}

.product-image {
    position: relative;
    width: 25%;
    display: flex;
    justify-content: center;
    /* max-height: 80%; */
}
.product-image img {
    max-width: 90%;
    /* max-height: 60%; */
}

.product-card .vbar {
    height: 40%;
}

.product-card .card-body {
    display: block;
    margin-left: 20px;
    width: 65%;
}

.product-card i {
    width: 16px;
    height: 16px;
}

.product-card .card-description {
    /* height: 45px;
    width: 300px; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    /* white-space: nowrap; */
}

.product-card:hover .card-description {
    animation: slide-content .3s cubic-bezier(0.215, 0.610, 0.355, 1.000) forwards;
}

.product-card:hover .freeze {
    animation: none;
}

@media screen and (max-width: 600px) {
    .product-card {
        height: 100px;
    }

    .product-card h2 {
        font-size: 18px;
    }

    .product-card .card-description {
        font-size: 12px;
    }
}

@keyframes slide-content {
    0% {
        bottom: 0;
        opacity: 1;
    }

    100% {
        bottom: -2%;
        opacity: 0;
        height: 0;
    }
}

.product-popup {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
}

.product-popup>.close-btn {
    position: absolute;
    top: -40px;
    right: calc(50% - 240px - 60px);
    width: 35px;
    height: 35px;
    border: none;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.1em;
}

.product-video-card {
    position: relative;
    padding: 10px;
    width: min(90%, 480px);
    height: 350px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    z-index: 0;
}

/* .product-video-card .video {
    width: 100%;
    height: 80%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
} */

.product-video-card .card-description {
    margin-top: 10px;
    height: 20%;
    /* padding-bottom: 20px; */
    overflow-y: scroll;
    /* Firefox */
    scrollbar-width: none;
    /* IE 10+ */
    -ms-overflow-style: none;
}

.product-video-card>.learn-more-btn {
    position: absolute;
    bottom: -60px;
    right: 0px;
    display: flex;
    padding: 6px 10px;
    border: none;
    background-color: #dc3232;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.4em;
}


.ambassador-wrapper {
    width: 100%;
    height: 300px;
    margin: 10px 0 40px 0;
    /* background-color: tomato; */
    display: flex;
    align-items: center;
}


.ambassador-wrapper.right {
    flex-direction: row-reverse;
}

.ambassador-tape {
    position: relative;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding-top: 20px;
    width: 60%;
    height: 66%;
    background-color: rgba(255, 255, 255, 0.75);
}

.ambassador-desc {
    position: absolute;
    width: 350px;
    height: 60%;
    padding: 10px 20px 0px 20px;
    font-size: 20px;
    line-height: 28px;
}

.ambassador-wrapper._left .ambassador-desc {
    left: 47%;
}

.ambassador-wrapper.right .ambassador-desc {
    right: 47%;
}

.ambassador-tape>.learn-more-btn {
    position: absolute;
    bottom: -16px;
    left: 50%;
    display: flex;
    padding: 6px 10px;
    border: none;
    background-color: #dc3232;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.3em;
}

.ambassador-tape>.learn-more-btn:hover {
    opacity: 0.75;
}

.ambassador-card {
    position: relative;
    /* left: -20px; */
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: clamp(200px, 16%, 350px);
    height: 100%;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px -1px 2px rgba(0, 0, 0, 0.07);
}

.ambassador-wrapper._left .ambassador-card {
    left: -20px;
    transform: translateY(5px) rotate(10deg);
}

.ambassador-wrapper.right .ambassador-card {
    right: -50px;
    transform: translateY(5px) rotate(-10deg);
}


.ambassador-card img {
    position: relative;
    max-height: 88%;
    /* max-width: 100%; */
}

.ambassador-card>.card-info {
    position: relative;
    width: 100%;
    height: 12%;
    padding: 0;
    justify-content: center;
}

.ambassador-card h2 {
    left: 0;
    font-size: 1.3em;
    margin: 0;
}


.jobslider {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 80%;
    margin-top: 50px;
    margin-left: 10%;
    height: 200px;
    /* background-color: white; */
    border-radius: 10px;
}

.jobs-list {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 100%;
    overflow-x: scroll;
    /* background-color: tomato; */
}


.jobs-list::-webkit-scrollbar {
    /* Chrome/Safari/Webkit */
    width: 0px;
    background: transparent;
}

.jobs-list {
    /* Firefox */
    scrollbar-width: none;
    /* IE 10+ */
    -ms-overflow-style: none;
}

.job-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
    /* width: 210px; */
    min-width: 210px;
    background-color: white;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
}

.job-card h2 {
    position: relative;
    top: 30%;
    max-width: 95%;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
}

.job-card em {
    position: absolute;
    bottom: 20%;
    cursor: pointer;
}

.job-card em:hover {
    color: #dc3232;
}

.chevron {
    position: relative;
    height: 100%;
    width: 5%;
    display: flex;
    align-items: center;
    /* background-color:rgba(0, 0, 0, 0.07); */
    justify-content: center;
}

.chevron i {
    cursor: pointer;
}


















.icons {
    color: #aaa;
    font-size: 2.7em;
    text-align: center;
    padding-top: 3em;
    padding-bottom: 1.5em;
}

.icon {
    color: #aaa;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
}

.icon:hover, .icon:active {
    color: #555;
}

.copyright {
    list-style: disc;
    color: #aaa;
    text-align: center;
    margin: 0;
    padding: 0;
    padding-bottom: 15px;
}

.copyright li:first-child {
    border: none;
}

.copyright li {
    display: inline-block;
    border-left: solid 1px rgba(144, 144, 144, 0.25);
    padding: 15px;
}

@media only screen and (max-width: 600px) {
    .copyright li {
        display: block;
        border: none;
        padding: 0px;
    }
}

.copyright li a {
    text-decoration: none;
    color: #aaa;
}

.copyright li a:hover, .copyright li a:active {
    color: #555;
}

#footer {
    background-color: #f6f6f6;
}

.icon-footer {
    color: none;
}